import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from "firebase";
import getFirebaseConfig from "./Firebase";
import './index.scss';


const environment = process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV
// console.log(process.env.REACT_APP_ENVIRONMENT)
// console.log(process.env.NODE_ENV)
// console.log(environment)


const firebaseConfig = getFirebaseConfig(environment);
firebase.initializeApp(firebaseConfig);
// console.log("Initialized with config:");
// console.log(firebaseConfig);

// const authProvider = new firebase.auth.OAuthProvider('microsoft.com');
// console.log("Auth provider:");
// console.log(authProvider)

//
// firebase.auth().signInWithPopup(authProvider)
//     .then(function(result) {
//         console.log(result)
//         console.log("Logged in user:");
//         console.log(firebase.auth().currentUser)

        const fireStore = firebase.firestore();
        ReactDOM.render(
            <React.StrictMode>
                <App store={fireStore}/>
            </React.StrictMode>,
            document.getElementById('root')
        );
//
//     })
//     .catch(function(error) {
//         console.log("poop")
//         // Handle error.
//     });
//
//
// console.log("d")



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


