import React, {useEffect, useState} from "react";
import TimeSlot from "./TimeSlot";
import {emptyTimeSlots, mapKey, mapKeyToDate, persistOneBooking, readBookings} from "../App";
import firebase from "firebase";
import {Col, Container} from "react-bootstrap";

interface DayProps {
    dateOfDay: Date,
    store: firebase.firestore.Firestore,
    secondsBetweenRefresh?: number
}

const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const Day = ({dateOfDay, store, secondsBetweenRefresh}: DayProps) => {

    const initialTimeSlots = emptyTimeSlots(dateOfDay, 6, 19)
    const [bookings, setBookings] = useState(initialTimeSlots)
    if(secondsBetweenRefresh && secondsBetweenRefresh > 59) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            const interval = setInterval(() => {
                console.log('Refreshing ' + dateOfDay + " every " + secondsBetweenRefresh + " seconds.");
                refreshBookings()
            }, secondsBetweenRefresh * 1000);
            return () => clearInterval(interval);
        });
    }
    useEffect(refreshBookings,[dateOfDay])

    function refreshBookings() {
        readBookings(dateOfDay, store)
            .then((b) => {
                setBookings(b)
            })
    }


    const updateBookings = (dateWithHour: Date) => {
        return (index: number) => {
            return (newName: string): Promise<any> => {
                return persistOneBooking(dateWithHour, newName, index, store)
                .then(() => {
                    let booking: string[] = [...bookings.get(mapKey(dateWithHour))!]
                    booking[index] = newName
                    bookings.set(mapKey(dateWithHour), booking)
                    setBookings(new Map(bookings))
                }).catch((e) =>{
                    refreshBookings()
                    throw e;
                })
            };
        };
    }


    let timeSlots: Date[] = Array.from(bookings.keys())
        .sort()
        .map((str:string): Date => {
            return mapKeyToDate(str)
        });

    let date = timeSlots[0].getDate()
    let month = timeSlots[0].getMonth()
    let dayOfWeek = timeSlots[0].getDay()
    let nameOfDay = dayNames[timeSlots[0].getDay()]
    let slots = timeSlots.map( timeSlot => {
            let timeEnd = new Date(timeSlot);
            timeEnd.setHours(timeSlot.getHours() + 1);
            let names = bookings.get(mapKey(timeSlot))!;
            return <TimeSlot key={timeSlot.toString()} timeStart={timeSlot} timeEnd={timeEnd} names={names} setNewName={updateBookings(timeSlot)}/>
        }
    )
    const today = new Date();
    const isToday = dateOfDay.getMonth() === today.getMonth()  && dateOfDay.getDay() === today.getDay()
    return <div className={"col-xl-6 col-lg-8 col-md-12 col-xs-24"} style={{paddingBottom:"8px"}}>
        <div className={`day${dayOfWeek === 0?" weekend":""}${isToday?" today" :""}`}>
            <h3>{nameOfDay} {date}/{month + 1}</h3>
            {slots}
        </div>
    </div>

}

export default Day;