const prodFirebaseConfig = {
    apiKey: "AIzaSyDUqISdal2fdLUFebRIITAS3NEsGRJY_go",
    authDomain: "booking-cc254.firebaseapp.com",
    databaseURL: "https://booking-cc254.firebaseio.com",
    projectId: "booking-cc254",
    storageBucket: "booking-cc254.appspot.com",
    messagingSenderId: "58923863548",
    appId: "1:58923863548:web:fc82d41b65f7765d3e7679"
};

const testFirebaseConfig = {
    apiKey: "AIzaSyCVRTVUsOxn0Dkg_sfELPv6L3vDRoBOcgY",
    authDomain: "booking-test-1ae42.firebaseapp.com",
    databaseURL: "https://booking-test-1ae42.firebaseio.com",
    projectId: "booking-test-1ae42",
    storageBucket: "booking-test-1ae42.appspot.com",
    messagingSenderId: "683956981979",
    appId: "1:683956981979:web:c181527af9c74c8c6fbcfe"
};


const config = {
    development: testFirebaseConfig,
    production: prodFirebaseConfig,
    test: testFirebaseConfig
};

const getFirebaseConfig = (environment: 'development' | 'production' | 'test') : any => {
    return config[environment]
}

export default getFirebaseConfig;