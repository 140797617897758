const allDatesInWeek = (inputDate:Date, weekOffset = 0) : Date[]=> {
    let curr = new Date(inputDate)
    curr.setDate(curr.getDate() + weekOffset * 7)
    let week = []

    for (let i = 1; i <= 7; i++) {
        let first = curr.getDate() - curr.getDay() + i
        let day = new Date(curr.setDate(first))
        week.push(day)
    }
    return week
}

export const datesBetween = (startDate:Date, endDate:Date) => {
    let curr = startDate
    let dates = []
    dates.push(new Date(curr))
    while (curr.getTime() < endDate.getTime()) {
        dates.push(new Date(curr.setDate(curr.getDate()+1)))
    }
    return dates

}

export default allDatesInWeek;