import React, {useEffect, useState} from 'react';
import './App.css';
import firebase from 'firebase/app';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'


import Day from "./components/Day";
import {
    BrowserRouter as Router,
    Link,
    NavLink,
    Redirect,
    Route,
    RouteProps,
    Switch,
    withRouter
} from "react-router-dom";
import allDatesInWeek from "./utils";
import {Button, Container, Nav, Navbar, Row} from "react-bootstrap";


const range = (start:number, end:number) => Array.from({length: (end - start)}, (v, k) => k + start);
const dateKey = (date: Date) => date.getFullYear() + "-" + date.getMonth().toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0");
const timeKey = (date: Date) => date.getHours().toString().padStart(2, "0") + ":" + date.getMinutes().toString().padStart(2, "0");
export const mapKey = (date:Date): string => {
    return date.getFullYear() + "-" + date.getMonth().toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0")+"-"+date.getHours().toString().padStart(2, "0") + "-" + date.getMinutes().toString().padStart(2, "0");

}
export const mapKeyToDate = ((str: string): Date => {
    let res = str.split("-")
    return new Date(parseInt(res[0]),parseInt(res[1]),parseInt(res[2]),parseInt(res[3]),parseInt(res[4]))
})

export const  readBookings = (date: Date, store:firebase.firestore.Firestore) =>  {
    let a: Promise<Map<any, any>> = store.collection("all-bookings")
        .doc(dateKey(date))
        .collection("bookings")
        .get()
        .then((collection) => {
            let bookings = emptyTimeSlots(date,6,19)
            collection.docs.forEach((doc) => {
                let hour = parseInt(doc.id.split(":")[0])
                let minute =  parseInt(doc.id.split(":")[1])
                let dateTime = new Date(date)
                dateTime.setHours(hour)
                dateTime.setMinutes(minute)
                bookings.set(mapKey(dateTime), doc.get("names") as string[])
            })
            return bookings;

        })
    return a
}


export const emptyTimeSlots = (date: Date, firstHour: number, lastHour: number):  Map<string, string[]> => {
    return new Map(range(firstHour, lastHour).map(hour => {
            return [
                mapKey(new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour,0)), ["", ""]
            ]
        }
    ))
}


export const persistOneBooking = (dateWithHour: Date, newName: string, index : number, store:firebase.firestore.Firestore): Promise<any> => {
    let documentReference = store.collection("all-bookings")
        .doc(dateKey(dateWithHour))
        .collection("bookings")
        .doc(timeKey(dateWithHour));

    return documentReference
        .get()
        .then((doc) => {
            let names = doc.get("names") as string[] || ["",""]
            if(names[index] === "" || newName === "") {   // Check if slot is still free
                names[index] = newName.trim()
                return documentReference.set({names: names})
            } else {
                throw {
                    message:"Someone beat you to it. Try another slot.",
                    name: names[index]

                }
            }
        })

}


interface AppProps {
    store: firebase.firestore.Firestore
}

enum LoginStatus {
    notLoggedIn,
    loginInProgress,
    loggedIn
}

const App = ({store}: AppProps) => {
    const today = new Date()

    const [loginStatus, setLoginStatus] = useState(LoginStatus.loginInProgress)

    const renderWeek = (weekOffset = 0, secondsBetweenRefresh = 0) => allDatesInWeek(today, weekOffset)
        .map((d: Date) => {
            return <Day key={d.toString()} dateOfDay={d} store={store} secondsBetweenRefresh={secondsBetweenRefresh}/>
        });

    firebase.auth().onAuthStateChanged(user => {
        if (user) {
            setLoginStatus(LoginStatus.loggedIn)
        } else {
            setLoginStatus(LoginStatus.notLoggedIn)
        }
    });

    const Navigation = ({location}: RouteProps) => {

        if(location?.pathname == "/refresh") {
            let seconds = 3 * 3600;
            console.log("Will refresh every " + seconds + " seconds.")
            let refreshInterval = setTimeout(() => {
                    clearInterval(refreshInterval);
                    console.log("BEFORE RELOAD")
                    window.location.reload(true);
                    console.log("AFTER RELOAD")
                },
                seconds * 1000);
            return null;
        }
        return <Navbar bg="light" variant="light">
            <Nav activeKey={location?.pathname}>
                <NavLink  to={"/this-week"} className={"nav-link"} activeClassName={"active"}>
                    <img
                        src="/logo256.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        alt="Gym Booking"
                    />
                </NavLink>
                <Nav.Item>
                    <NavLink to={"/this-week"} className={"nav-link"} activeClassName={"active"}> This week</NavLink>
                </Nav.Item>
                <Nav.Item>
                    <NavLink to={"/next-week"} className={"nav-link"} activeClassName={"active"}> Next week</NavLink>
                </Nav.Item>
                <Nav.Item>
                    <NavLink to={"/today"} className={"nav-link"} activeClassName={"active"}> Today</NavLink>
                </Nav.Item>
            </Nav>

            <Navbar.Collapse className="justify-content-end">
                <Navbar.Text style={{cursor:'pointer'}} onClick={()=> firebase.auth().signOut()}>
                    Signed in as: {firebase.auth().currentUser?.displayName}
                </Navbar.Text>
            </Navbar.Collapse>

        </Navbar>;
    }
    const HeaderWithRouter = withRouter(Navigation);

    return (
        <Router>
            <ReactNotification/>
            <Container>
                <HeaderWithRouter />
                <br/>
                <div className={"row"}>
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/this-week" />
                    </Route>
                    <PrivateRoute path="/this-week" loginStatus={loginStatus} setLoginStatus={setLoginStatus}>
                        {renderWeek()}
                    </PrivateRoute>
                    <PrivateRoute path="/next-week" loginStatus={loginStatus} setLoginStatus={setLoginStatus}>
                        {renderWeek(1)}
                    </PrivateRoute>
                    <PrivateRoute path="/today" loginStatus={loginStatus}  setLoginStatus={setLoginStatus} exact>
                        <Day dateOfDay={new Date()} store={store}/>
                    </PrivateRoute>
                    <Route path="/login" exact>
                        <Button onClick={()=> firebase.auth().signInWithRedirect(new firebase.auth.OAuthProvider('microsoft.com')) }> Login </Button>
                    </Route>
                    <Route exact path="/refresh">
                        {renderWeek(0, 60)}
                    </Route>
                </Switch>
                </div>
            </Container>
        </Router>
    );
}

interface PrivateRouteProps extends RouteProps {
    loginStatus: LoginStatus
    setLoginStatus: (ls: LoginStatus) => void
}

const PrivateRoute = ({ children, loginStatus, setLoginStatus, ...rest }: PrivateRouteProps) => {
    return (
        <Route {...rest}
            render={({ location }) => {
                switch(loginStatus) {
                    case LoginStatus.loggedIn:
                        return children
                    case LoginStatus.loginInProgress:
                        return <div>Login in progress</div>
                    case LoginStatus.notLoggedIn:
                        return <Button onClick={()=> {
                            firebase.auth().signInWithRedirect(new firebase.auth.OAuthProvider('microsoft.com'))
                            setLoginStatus(LoginStatus.loginInProgress)
                        }}> Login </Button>
                        }
                   }}
        />
    );
}

export default App;



