import React from "react";
import NameBox from "./NameBox";

interface TimeSlotProps {
    timeStart: Date,
    timeEnd: Date,
    names: string[]
    setNewName: (index: number) => (newName: string) => Promise<any>
}

const TimeSlot = ({timeStart, timeEnd, names, setNewName}: TimeSlotProps) => {
    let startMinutes
    let endMinutes
    if(timeStart.getMinutes() < 10) {startMinutes = "0" + timeStart.getMinutes().toString()}
    if(timeEnd.getMinutes() < 10) {endMinutes = "0" + timeEnd.getMinutes().toString()}
    return <div  className={"row"} style={{height:"1.8em"}}>
        <div><span className={"hours"}>{timeStart.getHours().toString().padStart(2,"0")}:{startMinutes}-{timeEnd.getHours().toString().padStart(2,"0")}:{endMinutes}</span></div>
        <div className={`col text-truncate name name-0 ${names[0] === "" ? "free": "busy"}`}><NameBox initialName={names[0]} setNewName={setNewName(0)}/></div>
        <div className={`col text-truncate name last name-1 ${names[1] === "" ? "free": "busy"}`}><NameBox initialName={names[1]} setNewName={setNewName(1)}/></div>
    </div>
}

export default TimeSlot;