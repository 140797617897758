import React, {useState} from "react";
import {store as notifier} from "react-notifications-component";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';


interface NameBoxProps {
    initialName: string;
    setNewName: (newName:string) => Promise<any>;
}

const NameBox = ({initialName, setNewName}: NameBoxProps) => {

    const [currentName, setCurrentName] = useState(initialName)

    const submitChange = () => {

        if (currentName.trim() !== "") {
        setNewName(currentName)
            .then(() => {
                notifier.addNotification({
                    title: "Booking successful",
                    message: "Booking was successfully completed.",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 2000,
                    }
                })
            })
            .catch((e: any) => {
                notifier.addNotification({
                    title: "Booking failed",
                    message: e.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 5000,
                    }
                });
                setNewName(e.name)
            })
        }
    };

    const handleChange = (e: any) => {
        if (e.keyCode === 13) {
            submitChange();
        } else {
            setCurrentName(e.target.value);
        }
    }

    const handleBlur =  (e: any) => {
        submitChange();
    }

    function resetName() {
        confirmAlert({
            message: 'Are you that you want to remove ' + initialName + "s booking!",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setNewName("").then(() => {
                            notifier.addNotification({
                                title: "Booking cancelled",
                                message: "Booking was successfully cancelled.",
                                type: "info",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 2000,
                                }
                            })
                        })
                        setCurrentName("");
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    if(initialName.length === 0) {
        return <input
            className={"name-box"}
            onChange={handleChange}
            onKeyDown={handleChange}
            onBlur={handleBlur}
            type="text"
            value={currentName}
            />
    } else {
        return <span
                    className={"name-box"}
                    onClick={resetName}
                    title={initialName}>
            {initialName}
        </span>

    }
}

export default NameBox;